import React from "react";
import logo from "../Images/circ4.png";
export const Services = (props) => {
  return (
    <div id="services">
      <div
        style={{ paddingBottom: "4%", paddingTop: "3%" }}
        className="container"
      >
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <img
              style={{ marginLeft: "-15%" }}
              src={logo}
              className="img-responsive"
              alt=""
              width="100%"
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2 style={{ color: "black", paddingTop: "10%" }}>
                BBPS SERVICES
              </h2>
              {/* <p>{props.data ? props.data.paragraph : "loading..."}</p> */}
              <h3 style={{ color: "black" }}>Why Choose Us?</h3>
              <div className="list-style">
                <div>
                  <ul style={{ fontSize: "16px" }}>
                    BBPS is to simplify bill payments nationwide, providing
                    convenient options for customers anytime, anywhere. The
                    objective of BBPS is to create an integrated and
                    interoperable bill payment system in the country, ensuring
                    accessible services through multiple payment methods and a
                    network of agents. Participants in BBPS include authorized
                    entities such as the BBPS operator, their agents, banks,
                    service providers, and others.
                  </ul>
                  <br />
                  <ul style={{ fontSize: "16px" }}>
                    1.Handle a variety of payments such as DTH/Cable TV,
                    Electricity, Broadband/Landline, Postpaid Mobile, Water,
                    Piped Gas, Education, FASTag Recharge, Credit Cards,
                    Municipal Tax, and Insurance.
                    <br />
                    <br />
                    2.Stay updated and manage your expenses effectively with our
                    user-friendly payment gateway service.
                    <br />
                    <br />
                    3.If you face any issues during a transaction or within the
                    app, report it directly through the app.
                    <br />
                    <br />
                    4.Our dedicated support team, keeping you informed through
                    in-app notifications at every step.
                    <br />
                    <br />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
