import React from 'react'
import { Button } from 'react-scroll';
import logo from "../../Images/ringpe_final_logo.png"
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function PrivacyPolicy() {
  const nav = useNavigate();
  return (

    <div style={{ margin: '5%' }}>

      <Button
        style={{
          fontSize: "80px",
          marginLeft: "-4%",
          position: "fixed",
          top: "1%",
          // right: "2%",
          // padding: "5px",
          backgroundColor: "transparent",
          border: "none",
          cursor: "pointer",
          color: "Black",

        }}
        onClick={() => nav(-1)}
      >
        < ArrowBackIcon style={{ fontSize: '25px' }} />
      </Button>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', }}>
        <img src={logo} alt="logo" style={{ width: "8%", marginTop: "-5%", marginLeft: "4%"  }} />
        <p style={{color:'#3746C8'}} >Powered By Sabado technologies</p>
      </div>
      {/* <img
                            href="#page-top"
                            src={logo}
                            alt="logo"
                            style={{ width: "8%",marginTop:'-32%' }}
                          /> */}

      <h2 style={{ textAlign:'center' }}>Privacy Policy</h2>

      <p
        id="modal-desc"
        // textColor="text.tertiary"
        fontSize={16}
        style={{ color: '#1f2a33' }}
      >
        We at Sabado Technologies Pvt Ltd (together with our
        affiliates, “RingPe”, “we”, “our” or “us”) respect
        your privacy and are strongly committed to keeping
        secure any information we obtain from you or about
        you. This Privacy Policy describes our practices
        with respect to Personal Information we collect from
        or about you when you use our website, applications,
        and services (collectively, “Services”). This
        Privacy Policy does not apply to content that we
        process on behalf of customers of our business
        offerings, such as our API. Our use of that data is
        governed by our customer agreements covering access
        to and use of those offerings. For information about
        how we collect information , please see this help
        center article.
        <br />
        <br />
        <h3>1. Personal information we collect We collect
        personal information relating to you (“Personal
        Information”) as follows:</h3>
        <br />
        <h4>Personal Information You Provide:</h4> We collect
        Personal Information if you create an account to use
        our Services or communicate with us as follows:
        <br />
        <br />
        <h4>• Account Information:</h4> When you create an account
        with us, we will collect information associated with
        your account, including your name, contact
        information, account credentials and transaction
        history, (collectively, “Account Information”).
        <br />
        <br />
        <h4>• User Content:</h4> When you use our Services, we
        collect Personal Information that is included in the
        input, file uploads, or feedback that you provide to
        our Services (“Content”). <br />
        <br />
        <h4>• Communication Information:</h4> If you communicate with
        us, we collect your name, contact information, and
        the contents of any messages you send
        (“Communication Information”).
        <br />
        <br />
        <h4>• Other Information You Provide:</h4> We collect other
        information that you may provide to us, such as when
        you participate in our events or surveys or provide
        us with information to establish your identity
        (collectively, “Other Information You Provide”
        Personal Information We Receive Automatically From
        Your Use of the Services: When you visit, use, or
        interact with the Services, we receive the following
        information about your visit, use, or interactions
        (“Technical Information”): • Log Data: Information
        that your browser or device automatically sends when
        you use our Services. Log data includes your
        Internet Protocol address, browser type and
        settings, the date and time of your request, and how
        you interact with our Services.
        <br />
        <br />
        <h4>• Usage Data:</h4> We may automatically collect
        information about your use of the Services, such as
        the types of content that you view or engage with,
        the features you use and the actions you take, as
        well as your time zone, country, the dates and times
        of access, user agent and version, type of computer
        or mobile device, and your computer connection.
        <br />
        <br />
        <h4>• Device Information:</h4> Includes name of the device,
        operating system, device identifiers, and browser
        you are using. Information collected may depend on
        the type of device you use and its settings.
        <br />
        <br />
        <h4>• Cookies: </h4>We use cookies to operate and administer
        our Services, and improve your experience. A
        “cookie” is a piece of information sent to your
        browser by a website you visit. You can set your
        browser to accept all cookies, to reject all
        cookies, or to notify you whenever a cookie is
        offered so that you can decide each time whether to
        accept it. However, refusing a cookie may in some
        cases preclude you from using, or negatively affect
        the display or function of, a website or certain
        areas or features of a website. For more details on
        cookies, please visit All About Cookies.
        <br />
        <br />
        <h3>2. How we use personal information</h3>
        <br />
        We may use Personal Information for the following
        purposes:
        <br />
        <br />
        • To provide, administer, maintain and/or analyze
        the Services;
        <br />
        <br />
        • To improve our Services and conduct research;
        <br />
        <br />
        • To communicate with you; including to send you
        information about our Services and events;
        <br />
        <br />
        • To develop new programs and services;
        <br />
        <br />
        • To prevent fraud, criminal activity, or misuses of
        our Services, and to protect the security of our IT
        systems, architecture, and networks;
        <br />
        <br />
        • To carry out business transfers; and
        <br />
        <br />
        • To comply with legal obligations and legal process
        and to protect our rights, privacy, safety, or
        property, and/or that of our affiliates, you, or
        other third parties.
        <br />
        <br />
        <h3>3. Disclosure of personal information</h3>
        <br />
        <h4>In certain circumstances we may provide your
        Personal Information to third parties without
        further notice to you, unless required by the law:</h4>
        <br />
        <h4>• Vendors and Service Providers:</h4> To assist us in
        meeting business operations needs and to perform
        certain services and functions, we may provide
        Personal Information to vendors and service
        providers, including providers of hosting services,
        customer service vendors, cloud services, email
        communication software, web analytics services, and
        other information technology providers, among
        others. Pursuant to our instructions, these parties
        will access, process, or store Personal Information
        only in the course of performing their duties to us.
        <br />
        <br />
        <h4>• Business Transfers:</h4> If we are involved in
        strategic transactions, reorganization, bankruptcy,
        receivership, or transition of service to another
        provider (collectively, a “Transaction”), your
        Personal Information and other information may be
        disclosed in the diligence process with
        counterparties and others assisting with the
        Transaction and transferred to a successor or
        affiliate as part of that Transaction along with
        other assets.
        <br />
        <br />
        <h4>• Legal Requirements:</h4> We may share your Personal
        Information, including information about your
        interaction with our Services, with government
        authorities, industry peers, or other third parties
        (i) if required to do so by law or in the good faith
        belief that such action is necessary to comply with
        a legal obligation, (ii) to protect and defend our
        rights or property, (iii) if we determine, in our
        sole discretion, that there is a violation of our
        terms, policies, or the law; (iv) to detect or
        prevent fraud or other illegal activity; (v) to
        protect the safety, security, and integrity of our
        products, employees, or users, or the public, or
        (vi) to protect against legal liability.
        <br />
        <br />
        <h4>• Affiliates:</h4> We may disclose Personal Information
        to our affiliates, meaning an entity that controls,
        is controlled by. Our affiliates may use the
        Personal Information we share in a manner consistent
        with this Privacy Policy.
        <br />
        <br />
        <h3>4. Your rights</h3>
        <br />
        <h4>Depending on location, individuals may have certain
        statutory rights in relation to their Personal
        Information. For example, you may have the right to:</h4>
        <br />
        • Access your Personal Information and information
        relating to how it is processed.
        <br />
        <br />
        • Delete your Personal Information from our records.
        <br />
        <br />
        • Rectify or update your Personal Information.
        <br />
        <br />
        • Transfer your Personal Information to a third
        party (right to data portability).
        <br />
        <br />
        • Restrict how we process your Personal Information.
        <br />
        <br />
        • Withdraw your consent—where we rely on consent as
        the legal basis for processing at any time. <br />
        <br />
        • Object to how we process your Personal
        Information.
        <br />
        <br />
        • Lodge a complaint with your local data protection
        authority.
        <br />
        <br />
        You can exercise some of these rights through your
        RingPe account. If you are unable to exercise your
        rights through your account, please submit your
        request to support@sabadotechnologies.com.
        <br />
        <br />
        <h3>
          5. Category of Personal Information Disclosure of
          Personal Information
        </h3>

        <br />
        Identifiers, such as your name, contact details, IP
        address, and other device identifiers We may
        disclose this information to our affiliates, vendors
        and service providers to process in accordance with
        our instructions; to law enforcement and other third
        parties for the legal reasons described above; to
        parties involved in Transactions; to corporate
        administrators of enterprise or team accounts; and
        to other users and third parties you choose to share
        it with.
        <br />
        <br />
        Commercial Information, such as your transaction
        history We may disclose this information to our
        affiliates, vendors and service providers to process
        in accordance with our instructions; to law
        enforcement and other third parties for the legal
        reasons described above; to parties involved in
        Transactions; and to corporate administrators of
        enterprise or team accounts.
        <br />
        <br />
        Network Activity Information, such as Content and
        how you interact with our Services We may disclose
        this information to our affiliates, vendors and
        service providers to process in accordance with our
        instructions; to law enforcement and other third
        parties for the legal reasons described above; to
        parties involved in Transactions; and to other users
        and third parties you choose to share it with.
        <br />
        <br />
        Geolocation Data We may disclose this information to
        our affiliates, vendors and service providers to
        process in accordance with our instructions; to law
        enforcement and other third parties for the legal
        reasons described above; and to parties involved in
        Transactions.
        <br />
        <br />
        Your account login credentials and payment card
        information (Sensitive Personal Information) We
        disclose this information to our affiliates, vendors
        and service providers, law enforcement, and parties
        involved in Transactions.
        <br />
        <br />
        <h4>To the extent provided for by local law and subject
        to applicable exceptions, individuals may have the
        following privacy rights in relation to their
        Personal Information:</h4>
        <br />
        • The right to know information about our processing
        of your Personal Information, including the specific
        pieces of Personal Information that we have
        collected from you;
        <br />
        <br />
        • The right to request deletion of your Personal
        Information;
        <br />
        <br />
        • The right to correct your Personal Information;
        and
        <br />
        <br />
        • The right to be free from discrimination relating
        to the exercise of any of your privacy rights.
        <br />
        <br />
        We don’t “sell” Personal Information or “share”
        Personal Information for cross-contextual behavioral
        advertising (as those terms are defined under
        applicable local law). We also don’t process
        sensitive Personal Information for the purposes of
        inferring characteristics about a consumer.
        <br />
        <br />
        Verification. In order to protect your Personal
        Information from unauthorized access, change, or
        deletion, we may require you to verify your
        credentials before you can submit a request to know,
        correct, or delete Personal Information. If you do
        not have an account with us, or if we suspect
        fraudulent or malicious activity, we may ask you to
        provide additional Personal Information and proof of
        residency for verification. If we cannot verify your
        identity, we will not be able to honor your request.
        <br />
        <br />
        <h3>6. Children</h3>
        <br />
        Our Service is not directed to children under the
        age of 18. If you have reason to believe that a
        child under the age of 18 has provided Personal
        Information to us through the Service, please email
        us at support@sabadotechnologies.com. We will
        investigate any notification and if appropriate,
        delete the Personal Information from our systems.{" "}
        <br />
        <br />
        <h3> 7. Links to other websites</h3>
        <br />
        The Service may contain links to other websites not
        operated or controlled by RingPe, including social
        media services (“Third Party Sites”). The
        information that you share with Third Party Sites
        will be governed by the specific privacy policies
        and terms of service of the Third Party Sites and
        not by this Privacy Policy. By providing these links
        we do not imply that we endorse or have reviewed
        these sites. Please contact the Third Party Sites
        directly for information on their privacy practices
        and policies.
        <br />
        <br />
        <h3>8. Security and Retention</h3>
        <br />
        We implement commercially reasonable technical,
        administrative, and organizational measures to
        protect Personal Information both online and offline
        from loss, misuse, and unauthorized access,
        disclosure, alteration, or destruction. However, no
        Internet or email transmission is ever fully secure
        or error free. In particular, email sent to or from
        us may not be secure. Therefore, you should take
        special care in deciding what information you send
        to us via the Service or email. In addition, we are
        not responsible for circumvention of any privacy
        settings or security measures contained on the
        Service, or third-party websites.
        <br />
        <br />
        We’ll retain your Personal Information for only as
        long as we need in order to provide our Service to
        you, or for other legitimate business purposes such
        as resolving disputes, safety and security reasons,
        or complying with our legal obligations. How long we
        retain Personal Information will depend on a number
        of factors, such as the amount, nature, and
        sensitivity of the information, the potential risk
        of harm from unauthorized use or disclosure, our
        purpose for processing the information, and any
        legal requirements.
        <br />
        <br />
        <h3>9. Changes to the privacy policy We may update this
        Privacy Policy from time to time. When we do, we
        will post an updated version on this page, unless
        another type of notice is required by applicable
        law.</h3>
        <br />
        <h3>10. How to contact us Please contact support if you
        have any questions or concerns not already addressed
        in this Privacy Policy.</h3>
        <br />
      </p>
      

    </div>
  )
}
