import React from "react";
import logo from "../Images/circ3svg.svg";

export const Advantages = (props) => {
  return (
    <div id="advantages">
      <div style={{}} className="container" >
        <div className="row">
          <div className="col-md-6 col-ms-12">
            <h2 style={{ paddingTop: "35%",marginBottom:'20%'}}>Advantages</h2>
            <h4 style={{ fontWeight: "bold",marginTop:'10%'}}>
              Why Choose RingPe ?
            </h4>

            <p style={{ fontSize: "16px" }}>
              1. Handle a variety of payments such as DTH/Cable TV, Electricity,
              Broadband/Landline, Postpaid Mobile, Water, Piped Gas, Education,
              FASTag Recharge, Credit Cards, Municipal Tax, and Insurance.
            </p> 

            <p style={{ fontSize: "16px" }}>
              2. Stay updated and manage your expenses effectively with our
              user-friendly payment gateway service.
            </p>

            <p>
              3. For assistance, reach out to our app support team via the
              'Contact support' option in the application
            </p>
            <p>
              4. Our dedicated support team, keeping you informed through
              in-app notifications at every step.
            </p>
          </div>
          <div className="col-md-6">
            <div>
              <img
                style={{ paddingTop: "20%", marginBottom:"8%"}}
                src={logo}
                className="img-responsive"
                alt=""
                width="100%"
      
              />{" "}
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};
