import React from "react";
import { Route, Router, Link, Routes } from "react-router-dom";
import HomePage from "./components/HomePage/HomePage";
import SmoothScroll from "smooth-scroll";
import ScrollToTop from "./components/ScrollTop/ScrollTop"

import "./App.css";
import TermsCondition from "./components/Terms_condition";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import { Switch } from "@mui/joy";
import Disclaimer from "./components/Disclaimer/Disclaimer";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/TermsCondition" element={<TermsCondition />} />
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy/>} />
      <Route path="/Disclaimer" element={<Disclaimer/>} />

    </Routes>
  );
};

export default App;
