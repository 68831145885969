import React from "react";
import logo from "../../Images/ringpe_logo.png"
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


export default function Disclaimer() {
const nav= useNavigate();


  return (
    <div style={{ margin:'5%'}}>
      <button
        style={{
            fontSize: "80px",
             marginLeft: "-4%",
            position: "fixed",
            top: "1%",
            // right: "2%",
            // padding: "5px",
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
            color: "Black",
           
          }}
        onClick={() => nav(-1)}
      >
       < ArrowBackIcon style={{fontSize:'25px'}}/>
      </button>

      <img src={logo} alt="logo" style={{ width: "8%", marginTop: "-5%" }} />
      <p
        id="modal-desc"
        textColor="text.tertiary"
        fontSize={16}
        style={{ justifyContent: "space-between",padding:'0px 6px 6px 0px ' }}
      >
      <h4>Disclaimer: RingPe App Services</h4> <br />
      The following disclaimer is intended to clarify the terms,
      responsibilities, and risks associated with the use of RingPe App services
      provided by Sabado Technologies Pvt Ltd. Please read this disclaimer
      carefully before utilizing our wallet services:
      <br />
      <br />
      1. General Information: Our App service is designed to facilitate Bill
      Payment Services. By using our App, you acknowledge and agree to the terms
      outlined in this disclaimer.
      <br />
      <br />
      2. Not Financial Advice: The information, content, and recommendations
      provided by RingPe App do not constitute financial or investment advice.{" "}
      <br />
      <br />
      3. Security: We employ rigorous security measures to protect your
      transactions. However, it is crucial that you also take responsibility for
      your App’s security. This includes safeguarding your passwords and keys,
      enabling two-factor authentication, and being cautious of phishing
      attempts. RingPe is not responsible for any loss of assets due to user
      negligence.
      <br />
      <br />
      4. Third-Party Services: Our App services may include links or
      integrations with third-party services or platforms. These third parties
      have their own terms and policies, and RingPe is not responsible for the
      actions or security of third-party services.
      <br />
      <br />
      5. Service Availability: While we strive to maintain the availability and
      reliability of our wallet services, we cannot guarantee uninterrupted
      access. Technical issues, maintenance, or external factors may temporarily
      disrupt our services.
      <br />
      <br />
      6. No Warranty: RingPe provides wallet services "as is" and makes no
      warranties, expressed or implied, regarding the accuracy, reliability, or
      fitness for a particular purpose of our services.
      <br />
      <br />
      7. Termination: RingPe reserves the right to terminate or suspend wallet
      services without notice. Users should be prepared for service
      interruptions and plan accordingly.
      <br />
      <br />
      8. Changes to Disclaimer: RingPe may update this disclaimer from time to
      time. Users should review the latest version on our website periodically.
      <br />
      <br />
      By using our wallet services, you acknowledge that you have read and
      understood this disclaimer and agree to abide by its terms. If you do not
      agree with any part of this disclaimer, we advise you not to use our
      wallet services. Your continued use of our services constitutes your
      acceptance of these terms and conditions.
      <br />
      <br />
      </p>
    </div>
  );
}
