import { useState } from "react";
import React from "react";
import final from "../Images/ringpe_final_logo.png";
import { Link } from 'react-router-dom';
const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [open1, setOpen1] = React.useState(false);

  return (
    <div id="contact">
      <div class='col-sm-6'>

      </div>
      <div className="container" style={{ height: '85vh', marginLeft: '10%', margintop: '1%' }}>
        <div className="col-md-8">
          <div className="row">
            <div className="section-title">
              <div className="col-md-3 col-md-offset-1 contact-info">
                <div className="contact-item">
                  <h3
                    style={{
                      color: "black",
                      textDecoration: "underline",
                      marginTop: '150%',

                    }}
                  >
                    Contact
                  </h3>
                  <p>
                    <span style={{ color: "black", }}>
                      <i
                        className="fa fa-map-marker"
                        style={{ color: "black", margintop: '1%' }}
                      ></i>
                      Website
                    </span>

                    <a
                      href="https://sabadotechnologies.com/"
                      target="_blank"
                      style={{ cursor: "pointer", color: '#3746C8' }}
                    >
                      www.sabadotechnologies.com
                    </a>
                  </p>
                </div>
                <div className="contact-item">
                  <p >
                    <span style={{ color: "black" }}>
                      <i
                        className="fa fa-phone"
                        style={{ color: "black" }}
                      ></i>
                      Phone
                    </span>
                    +917411136524
                  </p>
                </div>
                <div className="contact-item">
                  <p>
                    <span style={{ color: "black" }}>
                      <i
                        className="fa fa-envelope-o"
                        style={{ color: "black" }}
                      ></i>{" "}
                      Email
                    </span>{" "}
                    support@sabadotechnologies.com
                  </p>
                </div>
              </div>
              <div className="col-md-2 col-md-offset-5 contact-info" >
                <div className="contact-item" style={{ width: '150%', marginTop: '250%' }}>
                  <h3 style={{ color: "black", textDecoration: "underline", marginTop: '1%' }}>
                    Legal
                  </h3>
                  <p>
                    <span style={{ color: "black" }}>
                      <Link to="/TermsCondition"

                        variant="outlined"
                        color="neutral"
                        onClick={() => setOpen1(true)}
                        style={{
                          color: "black",
                          width: "20px",
                          cursor: "pointer",
                        }}
                      >  Terms & Conditions  </Link>
                    </span>
                  </p>
                </div>
                <div className="contact-item">
                  <p>
                    <Link
                      to="PrivacyPolicy"
                      variant="outlined"
                      color="neutral"
                      style={{
                        color: "black",
                        width: "20px",
                        cursor: "pointer",

                      }}
                    >
                      Privacy Policy
                    </Link>
                  </p>
                </div>
                <div className="contact-item">
                  <p>
                    <a
                      href="https://sabadotechnologies.com/"
                      target="_blank"
                      rel="nofollow"
                      style={{ color: "black" }}
                    >
                      About Us
                    </a>
                  </p>
                  <p>
                    <Link
                      to="/Disclaimer"

                      style={{
                        color: "black",
                        width: "20px",
                        cursor: "pointer",
                      }}
                    >
                      Disclaimer
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <img src={final} alt='logo' width='7%' style={{ margintop: '1%' }} />
          <p>

            <a href="https://sabadotechnologies.com/" target="_blank"
              rel="nofollow" color="#3746C8" >
              Powered By SabadoTechnologies
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
