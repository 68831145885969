import React from "react";
import logo from "../Images/cric2.png"
//import gradient from "../Images/gradients.svg"
export const Features = (props) => {
  return (
<div id="features">
<div  style={{paddingBottom:'8%',paddingTop:'4%'}} className="container">
  <div className="row">
    <div className="col-md-6">
    <div style={{ marginTop:'12%',marginLeft:'-9%'}}>
      <img src={logo} className="img-responsive" alt=""  width='100%'/>
      </div>
    </div>
    <div className="col-xs-12 col-md-6">
      <div className="about-text">
        <h2 style={{color:"black",paddingTop:"25%"}}>Features</h2>
        
      <div className="list-style">
          <div ><br/><br/>
            <ul  style={{fontSize:"16px"}}>
             	Effortlessly handle a variety of payments such as DTH/Cable TV, Electricity, Broadband/Landline, Postpaid Mobile, Water, Piped Gas, Education,
          FASTag Recharge, Credit Cards, Municipal Tax, and Insurance. Streamline your transactions with easy-to-use interface.
            </ul><br/><br/>
          </div>
          <div >
            <ul style={{fontSize:"16px"}}>
          Make bill payments using Credit Card, Debit Card, NetBanking, and UPI, all conveniently tracked within the app. Stay updated and manage your expenses 
       effectively with our user-friendly payment gateway services.
            </ul><br/>
            <ul style={{fontSize:"16px"}}>If you face any issues during a transaction or within the app, report it directly through the app.</ul>
          </div>
          <br/>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
  );
};
