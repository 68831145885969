import React from "react";
import logo from "../Images/circ3svg.svg";

export const Advantages = (props) => {
  return (
    <div id="advantages">
      <div style={{}} className="container" >
        <div className="row">
          <div className="col-md-6 col-ms-12">
            <h2 style={{ paddingTop: "35%",marginBottom:'20%'}}>Advantages</h2>
            <h4 style={{ fontWeight: "bold",marginTop:'10%'}}>
              Why Choose RingPe ?
            </h4>

            <p style={{ fontSize: "16px" }}>
             
1. Comprehensive Payment Handling: Easily manage a wide range of payments including DTH/Cable TV, Electricity, Broadband/Landline, Postpaid Mobile, Water, Piped Gas, Education, FASTag Recharge, Credit Cards, Municipal Tax, and Insurance.
            </p> 

            <p style={{ fontSize: "16px" }}>
            2. Effective Expense Management: Utilize our user-friendly payment gateway service to stay updated and manage your expenses efficiently.
            </p>

            <p>
            3. Accessible Support: For any assistance, reach out to our support team through the 'Contact Support' option in the app.
            </p>
            <p>
            4. Proactive Notifications: Our dedicated support team will keep you informed with in-app notifications at every step of the way.
            </p>
          </div>
          <div className="col-md-6">
            <div>
              <img
                style={{ paddingTop: "20%", marginBottom:"8%"}}
                src={logo}
                className="img-responsive"
                alt=""
                width="100%"
      
              />{" "}
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};
