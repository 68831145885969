// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 100%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #gallery,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 110%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;IACb,WAAW;EACb;EACA;;;;;;;IAOE,WAAW;EACb;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":["@media screen and (max-width: 400px) {\n  #features {\n    padding: 20px;\n    width: 100%;\n  }\n  #about,\n  #services,\n  #testimonials,\n  #team,\n  #contact,\n  #gallery,\n  #footer {\n    width: 100%;\n  }\n\n  #portfolio {\n    width: 110%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
