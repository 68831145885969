//import { Image } from "./image";
import React from "react";
//import styled from "styled-components";
// import { useSpring, animated } from "react-spring";

export const FAQ = (props) => {
  // const fadeIn = useSpring({
  //   opacity: 1,
  //   from: { opacity: 0 },
  // });
  return (
    // <div style={{background:'background: #f6f6f6;'}} id="faq" >
    // <animated.div style={{ ...fadeIn }} id="faq">
    <div id="faq" style={{ marginTop: "15%", paddingBottom: '5%' }} >
      <div className="container">
        {/* <h1 style={{ textAlign: "center",  marginTop:"15%"}}>
          FAQ
        </h1> */}
        <br /> <br /> <br />
        <p style={{ textAlign: "center", color: "black", marginTop: "8%" }}>
          <h2>Frequently Asked Questions</h2>
        </p>
        <div className="col-xs-12 col-md-6" style={{ marginTop: "6%", }}>
          <div className="about-text">
            {/* <p>{props.data ? props.data.paragraph : "loading..."}</p> */}
            <h3 style={{ color: "black" }}>
              1.What should I do if my transaction is pending but my account has been debited?
            </h3>
            <div className="list-style">
              <div>
                <ul>
                  If a transaction is pending due to issues with the external Bill Service Provider and we haven't received confirmation, we will follow up and resolve the issue for you. You may log this issue via the Mini Statement, and we will ensure it is addressed.
                </ul>
              </div>
              <div>
                <h3 style={{ color: "black" }}>
                  2. What if my transaction failed and the amount was not credited back to my account?

                </h3>

                <ul>
                  For failed transactions, the amount is automatically credited back to the source account. If this does not occur, you can log the issue via the Mini Statement, and we will follow up to ensure resolution.
                </ul>
              </div>
            </div>
            <div>
              <h3 style={{ color: "black" }}>3. How can I secure my app?</h3>

              <ul>
              To enhance security, change your password every six months and use complex passwords. Ensure privacy during login and usage of the app.
              </ul>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-md-6" style={{ marginTop: "6%", }}>
          <div className="about-text">
            {/* <p>{props.data ? props.data.paragraph : "loading..."}</p> */}
            {/* <h3 style={{color:"black"}}>4.	I am not able to fetch the bill details .</h3> */}
            <div className="list-style">
              <div>
                {/* <ul>
                Please note that there could be an issue with the biller and you should try after some time. You are also welcome to log the issue with us from the Grievance button provided and we will follow up the issue with the respective biller.
                </ul> */}
              </div>
              <div>
                <h3 style={{ color: "black" }}>
                4. How do I contact customer support for assistance?
                </h3>

                <ul>
              

Log any grievances directly through the app for a swift resolution. Alternatively, you can call us at 7411068800 or email support@sabadotechnologies.com.
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-md-6">
          <div className="about-text">
            <p></p>
            {/* <h3 style={{color:"black"}}>6.	My App seems to be hanging, what should I do?</h3> */}
            <div className="list-style">
              <div>
                {/* <ul>
                Please note that you should ensure to update your Mobile OS to the latest version to safeguard against security issues. Try to restart the Mobile 
                and check if the App is working, else reach out to our support at support@sabadotechnologies.com and we are more than happy to serve you.
                </ul> */}
              </div>
              <div>
                <h3 style={{ color: "black" }}>
                5. Should I share my OTP with anyone?
                </h3>

                <ul>
                

As a precaution against fraud, never share your OTP with anyone, including our staff. Always adhere to this best practice.
                </ul>

              </div>

            </div>
          </div>
        </div>
      </div>
      {/* </animated.div> */}
    </div>
  );
};
