//import { Image } from "./image";
import React from "react";
//import styled from "styled-components";
// import { useSpring, animated } from "react-spring";

export const FAQ = (props) => {
  // const fadeIn = useSpring({
  //   opacity: 1,
  //   from: { opacity: 0 },
  // });
  return (
    // <div style={{background:'background: #f6f6f6;'}} id="faq" >
    // <animated.div style={{ ...fadeIn }} id="faq">
    <div id="faq" style={{ marginTop:"15%",paddingBottom:'5%'}} >
      <div    className="container">
        {/* <h1 style={{ textAlign: "center",  marginTop:"15%"}}>
          FAQ
        </h1> */}
        <br /> <br /> <br />
        <p style={{ textAlign: "center", color: "black", marginTop: "8%" }}>
          <h2>Frequently Asked Questions</h2>
        </p>
        <div   className="col-xs-12 col-md-6" style={{ marginTop:"6%",}}>
          <div  className="about-text">
            {/* <p>{props.data ? props.data.paragraph : "loading..."}</p> */}
            <h3 style={{ color: "black"}}>
              1.What should I do if the transaction is shown as pending and my
              money is already debited from my account?
            </h3>
            <div className="list-style">
              <div>
                <ul>
                  Please note that a pending message is received if any issue
                  with the external Bill Service Provider’s service and if we do
                  not receive a confirmation of transaction. However please note
                  that it is our responsibility to ensure we follow up and close
                  this transaction for you. You are welcome to log this issue
                  with us from the Mini Statement but as we are already aware of
                  the status, we will follow up till closure.
                </ul>
              </div>
              <div>
                <h3 style={{ color: "black" }}>
                  2. The transaction has failed and I did not receive the amount
                  in my account.
                </h3>

                <ul>
                  Please note that for all failed transactions, the amount is
                  automatically credited back to the source account from which
                  you initially did the transaction. However, you are welcome to
                  log this issue with us from the Mini Statement, we will follow
                  up till closure.
                </ul>
              </div>
            </div>
            <div>
              <h3 style={{ color: "black" }}>3. How do I secure my App?</h3>

              <ul>
                Please ensure you change your password every 6 months and use
                secure passwords not easy to guess. Also ensure maintain privacy
                during login and usage of the App.
              </ul>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-md-6" style={{ marginTop:"6%",}}>
          <div className="about-text">
            {/* <p>{props.data ? props.data.paragraph : "loading..."}</p> */}
            {/* <h3 style={{color:"black"}}>4.	I am not able to fetch the bill details .</h3> */}
            <div className="list-style">
              <div>
                {/* <ul>
                Please note that there could be an issue with the biller and you should try after some time. You are also welcome to log the issue with us from the Grievance button provided and we will follow up the issue with the respective biller.
                </ul> */}
              </div>
              <div>
                <h3 style={{ color: "black" }}>
                  4. How do I reach the customer support for any issue?
                </h3>

                <ul>
                  Please note that you can log any grievance with us from the
                  App directly and this saves you valuable time. You can always
                  log grievance with us by calling us on 7411068800 or by
                  sending email to support@sabadotechnologies.com.
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-md-6">
          <div className="about-text">
            <p></p>
            {/* <h3 style={{color:"black"}}>6.	My App seems to be hanging, what should I do?</h3> */}
            <div className="list-style">
              <div>
                {/* <ul>
                Please note that you should ensure to update your Mobile OS to the latest version to safeguard against security issues. Try to restart the Mobile 
                and check if the App is working, else reach out to our support at support@sabadotechnologies.com and we are more than happy to serve you.
                </ul> */}
              </div>
              <div>
                <h3 style={{ color: "black"}}>
                  5. Should I share my OTP when asked by any one?
                </h3>

                <ul>
                  Please note that as a best practice against fraudsters you
                  should ensure that you never share OTP with any one including
                  our staff. Please follow this best practice always.
                </ul>
              
              </div>
              
            </div>
          </div>
        </div>
      </div>
    {/* </animated.div> */}
    </div>
  );
};
