import React from 'react'
import { Navigation } from '../navigation'
import { Header } from '../header'
import { Features } from '../features'
import { Advantages } from '../advantages'
import { FAQ } from '../faq'
import { Services } from '../services'
import { Contact } from '../contact'

export default function HomePage() {
  return (
    <div>
  <div>
      <Navigation />
      <Header />
      <Features  />
      <Advantages  />
      <FAQ />
      <Services  />
      <Contact  />
      </div>


      {/* <Routes>
      
        <Route path="/Terms_condition" element={<TermsCondition />} />
       </Routes>  */}
  </div>
  )
}
