import React from "react";
import logo from "../Images/circ4.png";
export const Services = (props) => {
  return (
    <div id="services">
      <div
        style={{ paddingBottom: "2%", paddingTop: "3%" }}
        className="container"
      >
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <img
              style={{ marginLeft: "-15%" }}
              src={logo}
              className="img-responsive"
              alt=""
              width="100%"
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2 style={{ color: "black", paddingTop: "5%" }}>
                BBPS SERVICES
              </h2>
              {/* <p>{props.data ? props.data.paragraph : "loading..."}</p> */}
              <h3 style={{ color: "black" }}>Why Choose Us?</h3>
              <div className="list-style">
                <div>
                  <ul style={{ fontSize: "16px" }}>
                    BBPS aims to simplify bill payments nationwide, offering convenient options to customers anytime, anywhere. Our goal is to create an integrated and interoperable bill payment system, ensuring accessibility through multiple payment methods and a network of agents. BBPS participants include authorized entities such as operators, agents, banks, service providers, and others.
                  </ul>
                  <br />
                  <ul style={{ fontSize: "16px" }}>
                    1. Comprehensive Payment Solutions: Handle a diverse range of payments including DTH/Cable TV, Electricity, Broadband/Landline, Postpaid Mobile, Water, Piped Gas, Education, FASTag Recharge, Credit Cards, Municipal Tax, and Insurance.
                    <br />
                    <br />
                    2. Effective Expense Management: Stay updated and manage your expenses efficiently with our user-friendly payment gateway service.
                    <br />
                    <br />
                    3. Easy Issue Reporting: Report any transaction or app-related issues directly through the app.
                    <br />
                    <br />
                    4. Dedicated Support: Our support team will keep you informed with in-app notifications throughout your experience.
                    <br />
                    <br />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
