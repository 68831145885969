import React from "react";

import logo from "../Images/ringpe_logo.png";
import { Button } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



export default function TermsCondition() {
  // const [open1, setOpen1] = React.useState(false);
  const nav = useNavigate();


  return (
    <div style={{ display: "Fixed",margin:'6%' }}>
      <Button
        style={{
          fontSize: "80px",
           marginLeft: "-4%",
          position: "fixed",
          top: "1%",
          // right: "2%",
          // padding: "5px",
          backgroundColor: "transparent",
          border: "none",
          cursor: "pointer",
          color: "Black",
         
        }}
        onClick={() => nav(-1)}
      >
        <ArrowBackIcon style={{fontSize:'25px'}}/>
      </Button>

      <img src={logo} alt="logo" style={{ width: "8%", marginTop: "-5%" }} />
      <h4>Terms & Conditions</h4>
      <p
        id="modal-desc"
        textColor="text.tertiary"
        fontSize={16}
        style={{ justifyContent: "space-between",padding:'0px 8px 6px 0px ' }}
      >
        1. These terms and conditions regulate the use of Recharge and Bill Pay
        services enabled by Sabado technologies Private Limited, a company
        incorporated under the Companies Act,2013 with its registered office at
        Silver Park, Whitefield, Bangalore, Karnataka – 560066, India
        (hereinafter referred to as “RingPe”/ “we”/”us”/” our”).
        <br />
        2.By proceeding to use of RingPe Recharge & Pay Bills, you (“User”/
        “you”/ “your”) signify your consent to be bound by these Terms of Use
        (hereinafter “Bill Pay T&Cs”). Whenever the context requires “User”/
        “you”/”your” shall mean any natural or legal person who is a resident of
        India, at least 18 (eighteen) years of age who is eligible to contract
        within the meaning of the Indian Contract Act, 1872, is not an
        undischarged insolvent and has registered on the RingPe App by accepting
        these Bill Pay T&Cs.
        <br />
        <br />
        3.By proceeding to use the RingPe Services which includes Recharge & Pay
        Bills offerings of RingPe, you shall be contracting with RingPe and
        these Bill Pay T & Cs including all policies referenced herein will
        constitute your binding obligations, with RingPe in connection with this
        offering.
        <br />
        <br />
        4.Further, the term “Merchants/ Billers’ shall include for the purpose
        of the Recharge & Pay Bills category any establishment and/or entity who
        is providing you utility services, payment services and accepts the
        RingPe wallet, UPI, Debit Card/ Credit Card (‘payment options’) as a
        payment method for online or offline purchase of utilities, payment
        services through an aggregator or a BBPO to whom you can make bill
        payment or recharge using RingPe App.
        <br />
        <br />
        5.When you transact using the RingPe App (with any payment options) for
        availing the Recharge & Pay Bills Services through the RingPe App T & Cs
        will be applicable to you in addition to the T&Cs of the respective
        Merchants.
        <br />
        <br />
        6.We reserve the right, at Our sole discretion, to change, modify, add
        or remove portions of these Terms of Use, at any time without any prior
        written notice to You. It is Your responsibility to review these Terms
        of Use periodically for updates / changes. Your continued use of RingPe
        App following the posting of changes will mean that You accept and agree
        to the revisions including additional Terms or removal of portions of
        these Terms, modifications etc. As long as You comply with these Terms
        of Use, We grant You a limited privilege to utilize the RingPe App for
        Recharge & Bill Pay offering and other services that maybe offered
        through RingPe App from time to time for payments, subscriptions,
        recharges, utility payments and any other recurring payments.
        <br />
        <br />
        <br />
        <h4>USING THE RINGPE RECHARGE & BILL PAY </h4>
        <br />
        <h6>
          FEATURE IN THE RINGPE APP INDICATES YOUR AGREEMENT TO ALL THE TERMS
          AND CONDITIONS. HENCE, PLEASE READ THESE TERMS CAREFULLY BEFORE
          PROCEEDING.
        </h6>
        <br />
        <p>
          By impliedly or expressly accepting the Recharge & Pay Bills T&Cs, you
          also agree and consent to be bound by all RingPe Policies including
          the Privacy Policy.
        </p>
        <br />
        <h4>General Terms for Recharge & Pay Bills</h4>
        <p>
          • Users may note that RingPe is only a facilitator of payments and is
          not a party to the payments.
          <br />
          <br />• RingPe facilitates Recharge and Pay Bills services by allowing
          you to pay for the Mobile Post-paid, Prepaid Recharge and landline
          Phone Bill Payment, DTH and Subscription for Streaming Services
          Payment, Other Utility Payments like Electricity, LPG etc. Credit Card
          Payment, Insurance Premium Payment, Donate online, Internet broadband
          and Data card Bill Payment, Municipal Tax & Water Tax payment, School
          Fees payment, Toll Tax recharge (FasTag), Loan repayment and other
          services provided by RingPe from time to time which are available
          under “Recharge and Pay Bills” section of Mobile APP through a)
          aggregators with whom RingPe has a contract or b) through the Bharat
          Bill Payment Operating Unit (BBPOU) infrastructure where the Merchant
          is registered with NPCI for Bill payments
        </p>
        <h4>Setting up Recharge & Pay Bills:</h4>
        <br />
        <p>
          • In order to make the recharge or bill payments you will have to
          provide the unique customer identity/ subscription identity number or
          bill number or registered mobile number, registered telephone number
          or such other identifier(s) which are required to fetch the
          payment/subscription due or bill value, subscription plan, due date,
          outstanding amount due and such other information necessary to enable
          the payment to your account with the Merchant.
          <br />
          • In order to make the recharge or bill payments you will have to
          provide the unique customer identity/ subscription identity number or
          bill number or registered mobile number, registered telephone number
          or such other identifier(s) which are required to fetch the
          payment/subscription due or bill value, subscription plan, due date,
          outstanding amount due and such other information necessary to enable
          the payment to your account with the Merchant.
          <br />
          • In order to make the recharge or bill payments you will have to
          provide the unique customer identity/ subscription identity number or
          bill number or registered mobile number, registered telephone number
          or such other identifier(s) which are required to fetch the
          payment/subscription due or bill value, subscription plan, due date,
          outstanding amount due and such other information necessary to enable
          the payment to your account with the Merchant.
          <br />
          • You authorize RingPe to access, fetch, share, use, store the
          information related to your account with the Merchant for Recharge &
          Bill Pay Services on an ongoing basis on your behalf for the said
          purposes.
          <br />
          • You understand that correctness of information is of utmost
          importance for fetching the right bill and subscription value and you
          accordingly confirm to ensure and be responsible for the correctness
          of the identifier information.
          <br />
          • You understand that the amount to be paid, recharged or subscription
          value is an agreement between you and the Merchant and RingPe has no
          obligation to verify the correctness of the same.
          <br />
          • You agree to keep your account information upto date and comply with
          the terms and conditions at all times otherwise RingPe has the right
          to suspend the account or deny any services.
          <br />
          • You agree that for providing the Recharge & Bill Pay Services the
          User identifier data, location/state and/or kyc information / any
          other personal information would be required to be shared with the
          Merchant/ biller for tax /GST purposes.
          <br />
          • You agree and authorize RingPe to communicate with the Merchant,
          third party service providers, aggregators with your account
          information for processing the transaction,
          <br />• You authorize RingPe to access, fetch, share, use, store the
          information related to your account with the Merchant for Recharge &
          Bill Pay Services on an ongoing basis on your behalf for the said
          purposes.
          <br />
          • You understand that correctness of information is of utmost
          importance for fetching the right bill and subscription value and you
          accordingly confirm to ensure and be responsible for the correctness
          of the identifier information.
          <br />
          • You understand that the amount to be paid, recharged or subscription
          value is an agreement between you and the Merchant and RingPe has no
          obligation to verify the correctness of the same.
          <br />
          • You agree to keep your account information upto date and comply with
          the terms and conditions at all times otherwise RingPe has the right
          to suspend the account or deny any services.
          <br />
          • You agree that for providing the Recharge & Bill Pay Services the
          User identifier data, location/state and/or kyc information / any
          other personal information would be required to be shared with the
          Merchant/ biller for tax /GST purposes.
          <br />
          • You agree and authorize RingPe to communicate with the Merchant,
          third party service providers, aggregators with your account
          information for processing the transaction, <br />
          • You authorize RingPe to access, fetch, share, use, store the
          information related to your account with the Merchant for Recharge &
          Bill Pay Services on an ongoing basis on your behalf for the said
          purposes.
          <br />
          • You understand that correctness of information is of utmost
          importance for fetching the right bill and subscription value and you
          accordingly confirm to ensure and be responsible for the correctness
          of the identifier information.
          <br />
          • You understand that the amount to be paid, recharged or subscription
          value is an agreement between you and the Merchant and RingPe has no
          obligation to verify the correctness of the same.
          <br />
          • You agree to keep your account information upto date and comply with
          the terms and conditions at all times otherwise RingPe has the right
          to suspend the account or deny any services.
          <br />
          • You agree that for providing the Recharge & Bill Pay Services the
          User identifier data, location/state and/or kyc information / any
          other personal information would be required to be shared with the
          Merchant/ biller for tax /GST purposes.
          <br />
          • You agree and authorize RingPe to communicate with the Merchant,
          third party service providers, aggregators with your account
          information for processing the transaction,
          <br />
          • You also agree that RingPe may set up reminder facility or auto
          payment facility which you expressly consent to and understand that
          payment once made to the Merchant for Recharge and Bill Pay is non –
          refundable.
          <br />
          • You shall be responsible for any duplicate standing instructions for
          payment or delayed payments or any penalty/ interest levied by the
          Merchant on the payments made. It is re-iterated that RingPe only
          facilitates the payments to the Merchants on your behalf.
          <br />
          <h4>Charges:</h4>
          <br />
          <p>
            • There may be charges for access, third party payment or such other
            data fees from third party payment participants and/ or Billers
            which you expressly agree and shall not hold RingPe liable for the
            same.
          </p>
          <br />
          <h4>Your Responsibilities: </h4>
          <br />
          In connection with your use of the RingPe Recharge & Pay Bills, it
          shall be your responsibility to adhere to the following:
          <br />
          <p>
            • You should verify from the transaction history and/or the
            notifications about the success or failure of the transaction.
            <br />
            • You shall be responsible for any charges levied by the
            Merchant/Biller in relation to the Recharge & Pay Bills services
            that can deducted from your account or that are added to your bill /
            subscription fees.
            <br />
            • You shall be responsible for keeping a track of your periodic
            bills, subscription fee and recharge expiries and or due dates of
            any utilities/ services or recurring charge services that you have
            availed and RingPe shall not be responsible for any technical issue
            related to periodic retrieval of the bills from the Billers or any
            errors / discrepancies in the Bills.
            <br />
            • You shall be responsible for scheduling your bill payment and you
            understand that the payment realization time shall vary from
            Merchant to Merchant and it is only based on your instructions that
            we will make the payment. We shall not be responsible for the
            delays/ reversals or failure of transaction.
            <br />
          </p>
        </p>
        <h4>User Errors:</h4>
        <p>
          If you erroneously send a payment to the wrong party or wrong biller
          or double pay or send a payment for the wrong amount (for instance a
          typographical error at your end) your only recourse will be to contact
          the Merchant /Party to whom you have sent the payment and ask them to
          refund the amount. RingPe will not reimburse you or reverse a payment
          that you have erroneously made.
        </p>
        <br />
        <h4>• Disclaimers:</h4>
        <br />
        <p>
          You agree that all risks arising from online transactions will be
          borne by You.
        </p>
        <br />
        <p>
          • RingPe and third-party partners make no warranty, express or implied
          regarding the quality of Services including but not limited to: i) the
          Services will meet your requirements; II) the Services will be
          uninterrupted, timely or error free; or III) any products, information
          or material obtained by You in connection with the services will meet
          Your requirements.
          <br />
          • Except as expressly provided herein and to the full extent permitted
          by law, the Wallet feature is provided “as is”, “as available” and
          “with all faults”. All such warranties, representations, conditions,
          undertakings and terms, whether express or implied, are hereby
          excluded. It is Your responsibility to evaluate the accuracy,
          completeness and usefulness of the Services and other information
          provided by RingPe or generally available. We do not authorize anyone
          to make any warranty on our behalf and You should not rely on any such
          statement.
          <br />
        </p>
        <br />
        <h4>Other Terms:</h4>
        <br />
        <p>
          All other terms including terms such as User Registration, Privacy,
          User Responsibilities, Indemnification, Governing Law, Liability,
          Intellectual Property, Confidentiality and General Provisions etc. are
          deemed to be incorporated into these Terms of Use by reference to the
          General Terms.
        </p>
      </p>
    </div>
  );
}
