// import React from "react";
// import logo from "../Images/first circ.png";
// // import gradient from '../Images/gradients.svg'

// export const Header = (props) => {
//   return (
//     <div id="header">
//    <div style={{marginBottom:20}} class="container" >   
//   <div class="row">
//     <div class='col-sm-6 col-sm-12'>
//        <div style={{
//                         fontSize: "400%",
//                         color: "black",
//                         fontWeight: "bold",
//                         top: "8%",
//                         position: "relative",
//                         font: "Clash Display",
//                         marginTop:'40%',
//                       }}>
//                       Make The Best  Payment Experience.
//                       <br />
//                       <p
//                         style={{
//                           color: "black",
//                           fontSize: "20px",
//                           marginTop: "10%",
//                         }}
//                       >
//                         Seamless Transactions In The Blink Of Any Eye.<br/>
//                         Where Your Finger Is The Key To A Cashless Tomorrow
//                       </p>
//                       </div>
//                        </div>
//                      <div class='col-sm-6 '>
//                    <div style={{ marginTop:'25%' }}>
//                   <img src={logo} alt='logo' width='80%'/>
//                 </div>
//     </div>
//     </div>
//     </div>
//     </div>
//   );
// };


import React from "react";
import TextTransition, { presets } from "react-text-transition";
import logo from "../Images/first circ.png";

const AnimatedText = ({ texts }) => {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      2000 // every 3 seconds
    );
    return () => clearInterval(intervalId);
  }, []);

  return (
    <h1>
      <TextTransition springConfig={presets.wobbly}>{texts[index % texts.length]}</TextTransition>
    </h1>
  );
};

export const Header = (props) => {
  const animatedTexts = [
    'Make The Best Payment Experience.',
    'Seamless Transactions In The Blink Of Any Eye.',
    //  'Where Your Finger Is The Key To A Cashless Tomorrow',
  ];

  return (
    <div id="header">
      <div style={{ marginBottom: 20 }} className="container">
        <div className="row">
          <div className="col-sm-6 col-sm-12">
            <div
              style={{
                color: "black",
                fontWeight: "bold",
                top: "8%",
                position: "relative",
                font: "Clash Display",
                marginTop: '40%',
              }}
            >
       <div style={{fontSize:'400px'}}>

              <AnimatedText texts={animatedTexts} style={{fontSize:'138'}} />
              </div>
              <br />
              <p
                style={{
                  color: "black",
                  fontSize: "20px",
                  marginTop: "10%",
                }}
              >
                Seamless Transactions In The Blink Of Any Eye.<br/>
                Where Your Finger Is The Key To A Cashless Tomorrow
              </p>
            </div>
          </div>
          <div className="col-sm-6">
            <div style={{ marginTop: '25%' ,marginLeft:'20%'}}>
              <img src={logo} alt='logo' width='100%' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
