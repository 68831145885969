import React from "react";
import logo from "../Images/cric2.png"
//import gradient from "../Images/gradients.svg"
export const Features = (props) => {
  return (
<div id="features">
<div  style={{paddingBottom:'8%',paddingTop:'4%'}} className="container">
  <div className="row">
    <div className="col-md-6">
    <div style={{ marginTop:'12%',marginLeft:'-9%'}}>
      <img src={logo} className="img-responsive" alt=""  width='100%'/>
      </div>
    </div>
    <div className="col-xs-12 col-md-6">
      <div className="about-text">
        <h2 style={{color:"black",paddingTop:"25%"}}>Features</h2>
        
      <div className="list-style">
          <div ><br/><br/>
            <ul  style={{fontSize:"16px"}}>
            Versatile Payment Options: Effortlessly manage payments for services such as DTH/Cable TV, Electricity, Broadband/Landline, Postpaid Mobile, Water, Piped Gas, Education, FASTag Recharge, Credit Cards, Municipal Tax, and Insurance through a streamlined, user-friendly interface.
            </ul><br/><br/>
          </div>
          <div >
            <ul style={{fontSize:"16px"}}>
            Convenient Payment Methods: Make payments using Credit Cards, Debit Cards, NetBanking, and UPI, all tracked conveniently within the app. Stay informed and manage your expenses efficiently with our intuitive payment gateway services.

            </ul><br/>
            <ul style={{fontSize:"16px"}}>Easy Issue Reporting: If you encounter any issues during a transaction or within the app, you can report them directly through the application for prompt resolution..</ul>
          </div>
          <br/>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
  );
};
